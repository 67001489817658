@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Ms+Madi&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&display=swap");

// ----- libs ------
@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";

// ----- vars -------
@import "./app/SCSS/mixins";
@import "./app/SCSS/variables";

// --- global styles ---
@import "./app/common.scss";
@import "./app/SCSS/buttons.scss";
@import "./app/SCSS/forms";
@import "./app/SCSS/tables";
@import "./app/SCSS/titles";
@import "./app/SCSS/modal";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
