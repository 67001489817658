.button {
  position: relative;
  min-width: 6rem;
  min-height: 3rem;
  color: var(--pure-text);
  font-size: 0.875rem;
  font-weight: 800;
  line-height: 1;
  letter-spacing: 0em;
  text-align: center;
  border: none;
  background-color: var(--light-blue);
  cursor: pointer;
  transition: all ease-out 0.18s;
  border-radius: 0.75rem;
  padding: 0.9063rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  &[disabled] {
    background-color: var(--thin-border);
    cursor: default;
  }

  &:hover:not([disabled]) {
    background-color: #4e95ff;
    transition: all ease-out 0.1s;
  }

  &:focus {
    outline: none;
  }

  & > img,
  & > svg {
    width: 0.875rem;
    margin-right: 0.75rem;
  }

  &_modal {
    min-width: 0;
    max-width: 6.875rem;
    padding: 1.5rem;
  }

  &_outline {
    background-color: var(--pure-surface);
    border: 0.0625rem solid var(--thin-border);
    color: var(--light-blue);

    &:hover:not([disabled]) {
      background-color: var(--thin-border);
    }

    &[disabled]::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      background-color: var(--text-pale);
    }
  }

  &_light {
    background-color: var(--light-bg);
    color: var(--light-blue);
    border: 0.0625rem solid transparent;

    &[disabled]::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      background-color: var(--text-pale);
    }

    &[disabled] {
      border: none;
    }

    &:hover:not([disabled]) {
      border: 0.0625rem solid var(--light-blue);
      background-color: var(--light-bg);
    }
  }

  &_warning {
    background-color: var(--main-red);

    &:hover {
      background-color: var(--main-red) !important;
      opacity: 0.8;
    }

    &[disabled] {
      pointer-events: none;
    }
  }

  &_warningLight {
    background: rgba(255, 0, 61, 0.06);
    border: 0.0625rem solid rgba(255, 0, 61, 0.13);
    color: var(--main-red);

    &:hover {
      background-color: var(--main-red) !important;
      color: var(--pure-text);
    }

    &[disabled] {
      pointer-events: none;
    }
  }

  &_success {
    background: var(--main-green);

    &:hover {
      background-color: var(--main-green) !important;
      opacity: 0.8;
    }

    &[disabled] {
      background-color: #6cebae !important;
      pointer-events: none;
    }
  }

  &_error {
    background-color: var(--main-red);

    &:hover {
      background-color: var(--main-red) !important;
      opacity: 0.8;
    }

    &[disabled] {
      background-color: #ffb2c5 !important;
      pointer-events: none;
    }
  }
}

.swiper-button-next,
.swiper-button-prev {
  width: 2rem;
  height: 2rem;
  background: var(--pure-surface);
  border-radius: 50%;
  z-index: 15;
  transform: translateY(50%);

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 0.875rem;
    height: 0.875rem;
    background: url("/../assets/icons/arrow-right.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.swiper-button-prev::after {
  transform: translateY(-50%) rotate(180deg);
}

.backArrowButton {
  background: var(--pure-surface);
  background-image: url("../../assets/icons/arrow-right.svg");
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: 50%;
  transform: rotate(180deg);
  border: none;
  cursor: pointer;
  box-shadow: 0rem 0.5rem 2rem rgba(0, 13, 134, 0.06);
  border-radius: 50%;
  width: 2.625rem;
  height: 2.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.5rem;
  flex-shrink: 0;
}

.swiper-pagination-bullet {
  background: var(--text-pale);
  width: 1.5625rem;
  height: 0.375rem;
  border-radius: 6.25rem;
  opacity: 1;

  &-active {
    background: var(--pure-surface);
  }
}

.swiper-button-disabled {
  pointer-events: all !important;
}

.swiper-pagination-horizontal {
  bottom: 0.3rem !important;
}
