.title {
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
  line-height: 3.5rem;
  color: var(--main-blue);

  &_sub {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
}
