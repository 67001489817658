.infoModal {
  display: grid;
  grid-template-rows: auto;
  row-gap: 1.5rem;
  text-align: left;

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    & > button {
      background: transparent;
      border: none;
      cursor: pointer;
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &__text {
    font-size: 1rem;
    line-height: 1.1875rem;
    color: var(--text-light);
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > button:not(:first-child) {
      margin-left: 1rem;
    }
  }
}
