.input {
  position: relative;
  background-color: var(--pure-surface);
  height: 3rem;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  display: flex;
  align-items: center;
  border-radius: 0.75rem;
  padding: 0;

  & > img {
    position: absolute;
    left: 0.875rem;
    width: 1.25rem;

    & ~ input {
      padding-left: 2.875rem;
    }
  }

  & > mat-datepicker-toggle {
    position: absolute;
    right: 0.75rem;
    width: 1.5rem;
    height: 1.5rem;

    & > button {
      width: inherit;
      height: inherit;
    }

    & img {
      display: block;
    }
  }

  & small {
    position: absolute;
    bottom: -0.875rem;
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 0.75rem;
    letter-spacing: 0em;
    text-align: left;
    color: red;
  }
}

.inputError {
  position: absolute;
  bottom: -0.875rem;
  left: 0;
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 0.75rem;
  letter-spacing: 0em;
  text-align: left;
  color: red;
}

input {
  border: 0.125rem solid var(--thin-border);
  border-radius: 0.75rem;
  min-width: 100%;
  min-height: 100%;
  outline: none;
  padding: 0 0.75rem;

  &.ng-invalid.ng-touched:not(:focus) {
    border-color: red;
  }
  &:focus {
    border-color: var(--light-blue);
  }
  &::placeholder {
    color: var(--text-lighter);
  }
}

.mat-calendar-body-selected {
  background-color: var(--light-blue);
  box-shadow: none !important;
}

.mat-calendar-body-today {
  border-color: var(--light-blue) !important;
}
