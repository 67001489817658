@import "./SCSS/variables";

*,
*::before,
*::after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

ul[class],
ol[class] {
  padding: 0;
}

html {
  font-size: 1.11vw;

  @media (min-width: 1440px) {
    font-size: 16px;
  }
}

body,
h1,
h2,
h3,
h4,
h5,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

body {
  min-height: 100vh;
  line-height: 1.5;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
}

ul[class],
ol[class] {
  list-style: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
}

article > * + * {
  margin-top: 1em;
}

input[type="number"] {
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  /* Firefox */
  -moz-appearance: textfield;
  appearance: textfield;
}

input,
button,
textarea,
select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  * {
    transition-duration: 0.01ms !important;
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
  }
}

body {
  color: var(--main-blue);
  font-family: Roboto, sans-serif;
  background-color: white;
}

.container {
  max-width: min(100%, var(--container-width));
  margin: 0 auto;
  padding: 0 1.875rem;

  &_s {
    max-width: min(100%, calc(var(--container-width) - 9.375rem));
  }

  &_fw {
    max-width: min(100%, calc(var(--container-width) + 11.25rem));
  }

  @include mobile {
    padding: 0 0.9375rem;
  }
}

.dialogue {
  width: 36rem;
  border: none;
  border-radius: 2rem;
  background-color: var(--secondary-surface);
}

.mat-tooltip {
  background: var(--pure-surface);
  border-radius: 0.5rem;
  padding: 0.625rem;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: var(--main-blue) !important;
  box-shadow: 0px 0.5rem 2rem rgba(0, 13, 134, 0.06);
}

.box {
  padding: 1.5rem;
  border-radius: 1.5rem;
  background-color: var(--pure-surface);
  display: grid;
  grid-template-rows: auto;
  row-gap: 2rem;
  max-width: 43.75rem;
  height: fit-content;
}

.toast {
  color: var(--main-blue);
  font-weight: 600;
  background-color: var(--pure-surface);
  min-width: 20rem !important;
  border: 0.0625rem solid var(--main-blue);
  border-radius: 0.75rem !important;
  padding: 0.75rem 1rem !important;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0rem 0.5rem 2rem rgba(0, 13, 134, 0.06);
  margin: 0 !important;
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;

  &_success {
    color: var(--main-green);
    border-color: var(--main-green);
  }

  &_error {
    color: var(--main-red);
    border-color: var(--main-red);
  }

  & .mat-simple-snackbar {
    font-size: 1rem;
  }
}

.mat-custom-controls {
  background: var(--light-blue);
}

.mat-clock-cell-selected {
  background-color: var(--light-blue) !important;
}

::-webkit-scrollbar {
  width: 0.625rem;
}

::-webkit-scrollbar-track {
  background: var(--secondary-surface);
  border-radius: 6.25rem;
}

::-webkit-scrollbar-thumb {
  background: var(--light-blue);
  border-radius: 6.25rem;
}
