.table-header-item {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.1875rem;
  color: var(--text-light);
  padding: 0.75rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-align: start;
  transition: all 0.15s ease;

  &:hover {
    color: #000;
  }
}

.table-item {
  border-radius: 0.75rem;
  transition: all 0.15s ease;

  &:hover {
    background: var(--secondary-surface);
  }

  & p {
    padding: 0.75rem;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
  }
}

.matTable {
  width: 100%;

  & .mat-header-row,
  & .mat-row {
    height: auto;
  }

  & .mat-header-cell,
  & .mat-cell {
    padding: 0.75rem;
    font-size: 1rem;
    line-height: 1.1875rem;

    &:first-of-type {
      padding-left: 0.75rem;
    }

    &:last-of-type {
      padding-right: 0.75rem;
    }
  }

  & .mat-header-cell {
    color: var(--text-light);
    font-weight: 700;
  }

  & .mat-cell {
    color: var(--main-blue);
    border-bottom: none;
    font-weight: 400;

    &:first-of-type {
      border-top-left-radius: 0.75rem;
      border-bottom-left-radius: 0.75rem;
    }

    &:last-of-type {
      border-top-right-radius: 0.75rem;
      border-bottom-right-radius: 0.75rem;
    }
  }

  & .mat-row {
    overflow: hidden;

    &:hover {
      background: var(--secondary-surface);
    }
  }

  & .mat-sort-header-arrow {
    min-width: 0.7rem;
    width: 0.7rem;
    height: 0.7rem;
    margin-left: 0.25rem;

    & .mat-sort-header-indicator {
      height: 0.1rem;
    }

    & .mat-sort-header-pointer-middle {
      width: 0.1rem;
      height: 0.1rem;
    }

    & .mat-sort-header-pointer-left,
    & .mat-sort-header-pointer-right {
      width: 0.35rem;
      height: 0.1rem;
    }

    & .mat-sort-header-middle {
      height: 0.1rem;
      width: 0.1rem;
    }

    & .mat-sort-header-stem {
      width: 0.1rem;
      height: 0.75rem;
    }
  }
}
