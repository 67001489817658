:root {
  --main-blue: #002152;
  --light-blue: #0066ff;
  --text-pale: rgba(255, 255, 255, 0.6);
  --text-light: rgba(0, 33, 82, 0.6);
  --text-lighter: rgba(0, 33, 82, 0.45);
  --button-light: rgba(0, 102, 255, 0.06);
  --main-green: #00cf6c;
  --main-red: #ff003d;
  --pure-text: #fff;
  --thin-border: rgba(0, 102, 255, 0.13);
  --pure-surface: #fff;
  --secondary-surface: #f4f8fb;
  --dark-surface: #ecf0f4;
  --light-bg: rgba(0, 102, 255, 0.06);
  --info: #ffc800;

  --container-width: 1440px;

  --swiper-pagination-bullet-horizontal-gap: 0.25rem;
}
