//--------DEVICE SIZE--------
$small-mobile: 23.4375rem;
$tablet-width: 40.5rem;
$desktop-width: 62.5rem;
$container-width: 77rem;

@mixin small-mobile {
  @media (max-width: #{$small-mobile - 0.0625rem}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$tablet-width - 0.0625rem}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width + 1px}) {
    @content;
  }
}

@mixin container {
  @media (min-width: #{$desktop-width}) and (max-width: #{$container-width}) {
    @content;
  }
}
